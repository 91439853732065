import { IEnv } from 'util/env'

export type EndpointConfig = {
  name: string
  config?: {
    endpoint?: string
  }
}

export const getEndpointsFromConfig = (endpointConfigs: EndpointConfig[]): string[] => {
  const endpoints = endpointConfigs.reduce((total: string[], endpointConfig) => {
    const endpoint = endpointConfig?.config?.endpoint
    if (endpoint) {
      total.push(endpoint)
    }
    return total
  }, [])
  return [...new Set(endpoints)]
}

export const getEndpointRegistry = (env: IEnv): EndpointConfig[] => {
  return [
    {
      name: 'shipping', // default registry with default endpoint (v2)
    },
    {
      name: 'shippingBasic',
      config: { endpoint: env.SHIPPING_ENDPOINT },
    },
    {
      name: 'shippingv1',
      config: { endpoint: env.SHIPPING_ENDPOINT_V1 },
    },
    {
      name: 'shipFrontend',
      config: {
        endpoint: env.SHIP_FRONTEND_ENDPOINT,
      },
    },
    {
      name: 'userService',
      config: { endpoint: env.USER_SERVICE_ENDPOINT },
    },
    {
      name: 'billOfLading',
      config: {
        endpoint: env.BILL_OF_LADING_ENDPOINT,
      },
    },
    {
      name: 'shipmentsV2',
      config: {
        endpoint: env.SHIPMENTS_V2_ENDPOINT,
      },
    },
    {
      name: 'cargoType',
      config: {
        endpoint: env.CARGO_TYPE_ENDPOINT,
      },
    },
    {
      name: 'documents',
      config: {
        endpoint: env.DOCUMENTS_ENDPOINT,
      },
    },
    {
      name: 'document-capture',
      config: {
        endpoint: env.DOCUMENT_CAPTURE_ENDPOINT,
      },
    },
    {
      name: 'partnerBooking',
      config: {
        endpoint: env.PARTNER_BOOKING_ENDPOINT,
      },
    },
    {
      name: 'customStatus',
      config: {
        endpoint: env.CUSTOM_STATUS_ENDPOINT,
      },
    },
    {
      name: 'transportOrder',
      config: {
        endpoint: env.TRANSPORT_ORDER_ENDPOINT,
      },
    },
    {
      name: 'foreignExchangeRate',
      config: {
        endpoint: env.FOREIGN_EXCHANGE_RATE_ENDPOINT,
      },
    },
    {
      name: 'contractManagement',
      config: {
        endpoint: env.CONTRACT_MANAGEMENT_ENDPOINT,
      },
    },
    {
      name: 'partnerMasterData',
      config: {
        endpoint: env.PARTNER_MASTER_DATA_ENDPOINT,
      },
    },
    {
      name: 'transportNetwork',
      config: {
        endpoint: env.TRANSPORT_NETWORK_ENDPOINT,
      },
    },
    {
      name: 'demDetCore',
      config: {
        endpoint: env.DEM_DET_CORE_ENDPOINT,
      },
    },
    {
      name: 'workbench',
      config: {
        endpoint: env.WORKBENCH_ENDPOINT,
      },
    },
    {
      name: 'auditLog',
      config: {
        endpoint: env.AUDITLOG_ENDPOINT,
      },
    },
    {
      name: 'billing',
      config: {
        endpoint: env.BILLING_ENDPOINT,
      },
    },
    {
      name: 'origin',
      config: {
        endpoint: env.ORIGIN_ENDPOINT,
      },
    },
    {
      name: 'macroView',
      config: {
        endpoint: env.MACRO_VIEW_ENDPOINT,
      },
    },
    {
      name: 'schedules',
      config: {
        endpoint: env.SCHEDULES_ENDPOINT,
      },
    },
    {
      name: 'ratesManagement',
      config: {
        endpoint: env.RATES_MANAGEMENT_ENDPOINT,
      },
    },
    {
      name: 'bookingRequests',
      config: {
        endpoint: env.BOOKING_REQUESTS_ENDPOINT,
      },
    },
    {
      name: 'quotation',
      config: {
        endpoint: env.QUOTATION_ENDPOINT,
      },
    },
    {
      name: 'feedv2',
      config: {
        endpoint: env.FEED_V2_ENDPOINT,
      },
    },
    {
      name: 'notificationCenter',
      config: {
        endpoint: env.NOTIFICATION_CENTER_ENDPOINT,
      },
    },
    {
      name: 'customerMasterData',
      config: {
        endpoint: env.CUSTOMER_MASTER_DATA_ENDPOINT,
      },
    },
    {
      name: 'catalog',
      config: {
        endpoint: env.CATALOG_ENDPOINT,
      },
    },
  ]
}
